import { Welcome } from "./Welcome.tsx"
import { PrepareYourGAHT } from "./Prepare.tsx"
import { ReadyToGAHT } from "./Ready.tsx"
import { YourGAHT } from "./YourGAHT.tsx"
import { useHasAtLeastOneRequestedPrescription } from "./tasks/ReviewDraft.tsx"
import { useGAHTSetupAppointment } from "./tasks/TalkWithAPhysician.tsx"
import { useGAHTConsented, useGAHTDraftPrescription, useOwns, useSubscription, useWelcomed } from "../../api.ts"
import { GAHTOnboarding_ID } from "@imago/model"
import { Temporal } from "@imago/api-client"
import { ProvGAHT } from "./ProvGAHT.tsx"

export function GAHT({fullScreen}: {fullScreen?: boolean}) {
	const welcomed = useWelcomed()!
	const hasGAHTOnboarding = useOwns(GAHTOnboarding_ID)
	const subscription = useSubscription()!
	const hasConsented = useGAHTConsented()!
	const setupAppointment = useGAHTSetupAppointment()
	const setupAppointmentPassed = setupAppointment ? Temporal.Instant.compare(setupAppointment.end.toInstant(), Temporal.Now.instant()) < 0 : false
	const hasAtLeastOneRequestedPrescription = useHasAtLeastOneRequestedPrescription()!
	const draftPrescription = useGAHTDraftPrescription() ?? null

	const setupAppointmentWasALongTimeAgo = setupAppointment ? Temporal.Instant.compare(setupAppointment.end.toInstant(), Temporal.Now.instant().subtract({hours: 60*24})) : false

	const gahtOnboarding =
		setupAppointmentWasALongTimeAgo ? "prov-gaht" :
		(hasConsented && hasAtLeastOneRequestedPrescription && subscription.periodEnds) ? "your-gaht" :
		(draftPrescription || hasAtLeastOneRequestedPrescription || setupAppointmentPassed) ? "ready-to-gaht" :
		(hasGAHTOnboarding || welcomed) ? "prepare-your-gaht" :
		"welcome"

	return gahtOnboarding === 'welcome' ? <>
		<Welcome fullScreen={fullScreen}/>
	</> : gahtOnboarding === 'prepare-your-gaht' ? <>
		<PrepareYourGAHT fullScreen={fullScreen}/>
	</> : gahtOnboarding === 'ready-to-gaht' ? <>
		<ReadyToGAHT fullScreen={fullScreen}/>
	</> : gahtOnboarding === 'your-gaht' ? <>
		<YourGAHT fullScreen={fullScreen}/>
	</> : gahtOnboarding === 'prov-gaht' ? <>
		<ProvGAHT fullScreen={fullScreen}/>
	</> : (gahtOnboarding satisfies never)
}
