import { useContext, useEffect, useState } from "react"
import { AppointmentView, useBook } from "../Book.tsx"
import { PageSetContext } from "../App.tsx"
import { CEOCall_ID, WelcomeCall_ID } from "@imago/model"
import { useServiceAppointment, useTESTSetWelcomed } from "../../api.ts"
import { Hello } from "../Hello.tsx"

function BookWelcomeCall() {
	const book = useBook()!

	return <section>
		<h2>Book a call</h2>
		<p><strong>By booking a welcome call you hereby confirm that you have read our <a href="https://www.imago.tg/principles-and-values/">Principles & Values</a>, <a href="https://www.imago.tg/mission-and-vision/">Mission & Vision</a>, and the <a href="https://www.imago.tg/faq/">Frequently Asked Questions</a>.</strong></p>
		<p>Get to know us, share any doubts or concerns you might have, tell us your story, and start or continue your GAHT with Imago.</p>
		<button type="submit" onClick={ev => {
			ev.stopPropagation()
			ev.preventDefault()
			book.mutate({serviceID: WelcomeCall_ID})
		}}>Book a welcome call</button>
		<hr/>
		<p>Zofia: Welcoming everyone in person has had a great value to me but could not be sustained. Our excellent team takes majority of calls now. However, I do not want to deprive any of you of the option, especially that many want to offer their help and support for the community, work for us, or discuss ideals and ideas, policies and politics, values, and strategies. I assure you, your voice matters. You matter. If you want to have a welcome call with me you are warmly invited to.</p>
		<button onClick={ev => {
			ev.stopPropagation()
			ev.preventDefault()
			book.mutate({serviceID: CEOCall_ID})
		}}>Book a call with Zofia</button>
	</section>
}

export function Welcome({fullScreen}: {fullScreen?: boolean}) {
	const skip = useTESTSetWelcomed()

	const welcomeAppointment = useServiceAppointment(WelcomeCall_ID)
	const ceocallAppointment = useServiceAppointment(CEOCall_ID)
	const setPage = useContext(PageSetContext)

	const appointment = (welcomeAppointment === undefined || ceocallAppointment === undefined) ? undefined : welcomeAppointment ?? ceocallAppointment

	const [wasNotBooked, setWasNotBooked] = useState(false)
	useEffect(() => {
		if (appointment === null)
			setWasNotBooked(true)
	}, [appointment])

	if (appointment === undefined) return

	return (
		appointment ? (
			fullScreen ? (
				<main>
					<Hello/>
					<section>
						{wasNotBooked ? <h1>Thanks, see you!</h1> : <></>}
						{wasNotBooked ? <p>Confirmation was sent to your email address.</p> : <></>}
						<AppointmentView appointment={appointment} showTips={true}/>
					</section>
					{skip ? <button disabled={skip.isPending} onClick={ev => {
						ev.preventDefault()
						ev.stopPropagation()
						skip.mutate()
					}} type="submit">TEST: Skip until after the welcome call</button> : <></>}
				</main>
			) : (
				<div style={{marginBottom: '-33px'}}/>
			)
		) : (
			fullScreen ? (
				<main>
					<Hello>
						<p className="display-small">Welcome to Imago.</p>
					</Hello>
					<BookWelcomeCall/>
				</main>
			) : (
				<section>
					<h2>Welcome to Imago!</h2>
					<p>Want to start your Gender-Affirming Hormone Therapy at Imago?</p>
					<button type="submit" onClick={ev => {
						ev.preventDefault()
						ev.stopPropagation()
						setPage('gaht')
					}}>Book a Welcome call</button>
				</section>
			)
		)
	)
}
