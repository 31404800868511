const GDrive_FileID__Brand: unique symbol = Symbol()
export type GDrive_FileID = string & {[GDrive_FileID__Brand]: never}

const GDrive_FolderID__Brand: unique symbol = Symbol()
export type GDrive_FolderID = GDrive_FileID & {[GDrive_FolderID__Brand]: never}

const GDrive_DocumentID__Brand: unique symbol = Symbol()
export type GDrive_DocumentID = GDrive_FileID & {[GDrive_DocumentID__Brand]: never}

const GCalendar_CalendarID__Brand: unique symbol = Symbol()
export type GCalendar_CalendarID = string & {[GCalendar_CalendarID__Brand]: never}

const GCalendar_EventID__Brand: unique symbol = Symbol()
export type GCalendar_EventID = string & {[GCalendar_EventID__Brand]: never}

import type { GoogleWorkspaceRemote, Google_Auth } from './_base.ts'
export type { GoogleWorkspaceRemote, Google_Auth }

import { GoogleWebRemote } from './web.ts'
export { GoogleWebRemote }

import type { CalendarV3_CalendarList, CalendarV3_Event } from './CalendarV3.ts'
export type { CalendarV3_CalendarList, CalendarV3_Event }

import { CalendarV3_CalendarList_list, CalendarV3_Events_get, CalendarV3_Events_list, CalendarV3_Events_patch } from './CalendarV3.ts'
export { CalendarV3_CalendarList_list, CalendarV3_Events_get, CalendarV3_Events_list, CalendarV3_Events_patch }

import { DocsV1_Documents_batchUpdate, DocsV1_Documents_create, DocsV1_Documents_get } from './DocsV1.ts'
export { DocsV1_Documents_batchUpdate, DocsV1_Documents_create, DocsV1_Documents_get }

import { DriveV3A_Files_create, DriveV3A_Files_get, DriveV3A_Files_getMedia, DriveV3A_Files_list, DriveV3A_Files_update } from './DriveV3.ts'
export { DriveV3A_Files_create, DriveV3A_Files_get, DriveV3A_Files_getMedia, DriveV3A_Files_list, DriveV3A_Files_update }

import { SheetsV4_Spreadsheets_batchUpdate, SheetsV4_Spreadsheets_get, SheetsV4_Spreadsheets_updateValues } from './SheetsV4.ts'
export { SheetsV4_Spreadsheets_batchUpdate, SheetsV4_Spreadsheets_get, SheetsV4_Spreadsheets_updateValues }
