import { useContext } from "react"
import { Hello } from "../Hello.tsx"
import { PageSetContext } from "../App.tsx"
import { TakeBloodTests, useBloodTestsTaken } from "./tasks/TakeBloodTests.tsx"
import { useLastGAHTBloodResults } from "../../api.ts"

const locale = 'en-GB'

export function ProvGAHT({fullScreen}: {fullScreen?: boolean}) {
	const lastBloodResults = useLastGAHTBloodResults()
	const bloodTestsDone = useBloodTestsTaken()

	const Section = fullScreen ? 'main' : 'div'
	const H = fullScreen ? 'h1' : 'h2'

	const setPage = useContext(PageSetContext)

	return <Section>
		{fullScreen ? <Hello/> : <></>}
		<section>
			<H>Your GAHT</H>
			{lastBloodResults ? <p>Your last blood tests results were sent on <span>{lastBloodResults?.toLocaleString(locale)}</span>.</p> : <></>}
			{!fullScreen && !bloodTestsDone ? <>
				<button type="submit" onClick={ev => {
					ev.preventDefault()
					ev.stopPropagation()
					setPage('gaht')
				}}>Send new blood tests results</button>
			</> : <></>}
		</section>
		{!fullScreen ? <></> : <>
			<hr data-total/>
			<TakeBloodTests/>
		</>}
	</Section>
}
