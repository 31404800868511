import { Temporal } from "@imago/api-client"
import { TypedEventTarget } from "typescript-event-target"
import { Google_Auth, GoogleWorkspaceRemote } from "./_base.ts"

interface GoogleMessage {
	method: 'fireIdpEvent' | string,
	params: {
		clientId: string,
		id: string,
		type: 'authResult' | string,
		authResult?: GoogleAuthResult,
	}
}

interface GoogleAuthResult {
	access_token: string
	token_type: 'Bearer'
	expires_in: number,
	scope: string,
	authuser: string,
	hd: string,
}

interface ExpiringAuthResult extends GoogleAuthResult {
	expires_at: Temporal.Instant_ISO
}

interface OIDCUserInfo {
	sub: string
	email: string
	email_verified?: boolean
	hd?: string
	name?: string
	given_name?: string
	family_name?: string
	picture?: string
}

export class GoogleWebRemote extends TypedEventTarget<{auth: Event}> implements GoogleWorkspaceRemote {
	private clientID: string
	private hd?: string

	private lastAuthResult: ExpiringAuthResult | undefined

	constructor(clientID: string, {hd}: {hd?: string} = {}) {
		super()

		this.clientID = clientID
		this.hd = hd

		const storedAuth = localStorage.getItem('auth')
		if (storedAuth) {
			this.lastAuthResult = JSON.parse(storedAuth)
		}
	}

	async getNewAccessToken({prompt}: {prompt?: 'select_account'} = {}) {
		let listener: ((ev: MessageEvent<string>) => void) | undefined = undefined
		try {
			return await new Promise<void>((resolve, reject) => {
				let done = false

				const now = Temporal.Now.instant()
				const requestId = 'auth' + Math.round(Math.random() * 1000000)

				listener = (ev: MessageEvent<string>) => {
					if (ev.origin !== "https://accounts.google.com") return

					const data = JSON.parse(ev.data) as GoogleMessage
					if (!(data.method === 'fireIdpEvent' && data.params.id === requestId && data.params.type === 'authResult' && data.params.authResult)) return

					this.lastAuthResult = {
						...data.params.authResult,
						expires_at: now.add({seconds: data.params.authResult.expires_in}).toString()
					}
					localStorage.setItem('auth', JSON.stringify(this.lastAuthResult))
					this.dispatchTypedEvent('auth', new Event('auth'))

					done = true
					resolve()
				}
				window.addEventListener('message', listener)
				const popup = window.open(
					'https://accounts.google.com/o/oauth2/v2/auth?' + new URLSearchParams({
						gsiwebsdk: '3',
						client_id: this.clientID,
						scope: 'openid profile email https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events',
						redirect_uri: `storagerelay://${location.protocol.substring(0, location.protocol.length - 1)}/${location.host}?id=${requestId}`,
						prompt: prompt ?? 'none',
						...(this.hd ? {hd: this.hd} : {}),
						response_type: 'token',
						include_granted_scopes: 'true',
					}),
					'g_auth_token_window',
					'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=500,height=550',
				)
				if (!popup) {
					reject(new Error('Cannot open popup.'))
					return
				}
				setTimeout(() => {
					if (!done) return
					reject(new Error('Auth timeout'))
				}, 30000)
			})
		} finally {
			if (listener)
				window.removeEventListener('message', listener)
		}
	}

	private get hasReadyAccessToken() {
		return this.lastAuthResult !== undefined && Temporal.Instant.compare(this.lastAuthResult.expires_at, Temporal.Now.instant().add({seconds: 60})) > 0
	}

	isSignedIn() {
		return this.hasReadyAccessToken
	}

	async signIn() {
		await this.getNewAccessToken({prompt: 'select_account'})
	}

	async getAuth(): Promise<Google_Auth> {
		if (!this.hasReadyAccessToken)
			await this.getNewAccessToken()
		if (!this.lastAuthResult)
			throw new Error('Not authenticated')
		return {
			accessToken: this.lastAuthResult.access_token
		}
	}

	async getUserInfo(): Promise<OIDCUserInfo | null> {
		try {
			return await (await fetch('https://openidconnect.googleapis.com/v1/userinfo', {headers: {'Authorization': `Bearer ${(await this.getAuth()).accessToken}`}})).json()
		} catch (e) {
			console.error(e)
			return null
		}
	}
}
