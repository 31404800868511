import { AppointmentInfo, ASSISTANT_CHECK_STATUSES, CALL_STATUSES, EMRID, EMRInfo, EMRKindID, FatUser, GAHTAssistantCheck_WithPreCallSummary_ID, GAHTBloodResults_ID, GAHTFHT_ID, GAHTFHTConsent_ID, GAHTIntake_ID, GAHTMHT_ID, GAHTMHTConsent_ID, GAHTSetupCall_WithPrescriptionDraft_ID, Instant, Intent, IntentRequest, isProduct, isService, isServiceProduct, Migrated_ID, MimeType, PlainDate, PrescriptionContent, PrescriptionRequestContent, ProductID, ProductInfo, Profile_ID, ServiceID, ServiceInfo, ServiceProductID, ServiceProductInfo, SubscriptionInfo, TEMPORARILY_HERE_productsAndServices, TestUserTemplateID, TherapyID, UserProfileContent, WelcomeCall_ID, WelcomeCall_WithWelcomed_ID, Welcomed_ID } from "@imago/model"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"
import ky, { HTTPError } from "ky"
import { Temporal } from "@imago/api-client"
import { TF_FormID } from "@imago/api-client-typeform"
import { serialize, deserialize } from "@deepkit/type"

const API_BASE = process.env.BACKEND_URI ?? 'http://localhost:8080'

export function useConfig() {
	const fatUser = useFatUser()!
	return fatUser.appConfig!
}

const api = ky.create({
	prefixUrl: API_BASE,
	timeout: false,
	hooks: {
		beforeRequest: [
			request => {
				const token = localStorage.getItem('access_token')
				if (token) {
					request.headers.set('authorization', `Bearer ${token}`)
				}
			}
		]
	}
})

interface SerializedSubscriptionInfo {
	periodEnds: string | null
}

interface SerializedFatUser {
	subscription?: SerializedSubscriptionInfo
}

function deserializeFatUser(data: unknown) {
	const base = deserialize<FatUser>(data)

	// Work around bugs in Deepkit.

	const periodEnds = (data as SerializedFatUser).subscription?.periodEnds
	if (periodEnds !== undefined) {
		base.subscription!.periodEnds = periodEnds === null ? null : Instant.from(periodEnds)
	}

	return base
}

export function useSendSignInMessage(onSuccess: ({email}: {email: string}) => void) {
	return useMutation({
		async mutationFn({email}: {email: string}) {
			const nonce = crypto.randomUUID()
			localStorage.setItem('nonces', nonce)
			await api.post('auth/by-email/', {
				json: {
					email,
					nonce,
				}
			})
			return {email}
		},
		onSuccess,
	})
}

export function useSignInAsATestUser() {
	const config = useConfig()
	const mutation = useMutation({
		async mutationFn({templateID}: {templateID: TestUserTemplateID}) {
			localStorage.setItem('nonces', 'test')
			window.location.href = `${API_BASE}/auth/sign-in-as-a-test-user/?templateID=${templateID}&nonce=test`
		}
	})
	return config.testMode ? mutation : null
}

export function useTfFile_internal() {
	const cacheManager = useGAHTEMRsCacheManager()

	const mutation = useMutation({
		mutationFn: async ({form, response}: {form: TF_FormID, response: string}) => {
			try {
				const emr = deserialize<EMRInfo>(await api.post('typeform/', {
					json: {
						form,
						response,
					},
				}).json())

				cacheManager.add(emr)
			} finally {
				cacheManager.invalidate()
			}
		}
	})

	return mutation
}

function useFatUser() {
	return useQuery({
		queryKey: ['fat-user'],
		staleTime: Infinity,
		queryFn: async () => {
			let patches = new Array<FatUser>()

			const hashParams = new URLSearchParams(document.location.hash.substring(1))
			const searchParams = new URLSearchParams(document.location.search.substring(1))

			const idToken = hashParams.get('id_token')
			const nonces = localStorage.getItem('nonces') || ''

			if (idToken) {
				try {
					const {access_token: accessToken} = await api.put('auth/session/', {
						json: {
							id_token: idToken,
							nonces
						},
					}).json<{access_token: string}>()
					localStorage.setItem('access_token', accessToken)
				} catch (e) {
					try {
						if (e instanceof HTTPError) {
							console.error('Sign-in failed.', (await e.response.json()).message)
						} else {
							console.error('Sign-in failed.', e)
						}
					} catch (ee) {
						console.error('Sign-in failed.', e)
						console.warn(ee)
					}
				}
			}

			const setupIntent = searchParams.get('setup_intent')
			const paymentIntent = searchParams.get('payment_intent')
			const redirectStatus = searchParams.get('redirect_status')

			if ((setupIntent ?? paymentIntent) && redirectStatus === 'succeeded') {
				const patch = deserializeFatUser(await api.post('stripe/', {
					json: {
						intent: setupIntent ?? paymentIntent
					},
				}).json())
				patches.push(patch)
			}
			searchParams.delete('setup_intent')
			searchParams.delete('payment_intent')
			searchParams.delete('redirect_status')

			const url = new URL(document.location.href)
			url.search = searchParams.toString()
			url.hash = hashParams.toString()
			if (document.location.href != url.toString()) {
				window.history.replaceState(null, '', url)
			}

			let data
			try {
				data = deserializeFatUser(await api.get('').json())
			} catch (e) {
				if (e instanceof HTTPError) {
					if (e.response.status === 401) {
						return null
					}
				}
				throw e
			}
			for (const patch of patches) {
				data = {...data, ...patch}
			}
			if (data) {
				const url = new URL(document.location.href)
				url.search = ''
				url.hash = ''
				if (document.location.href != url.toString()) {
					window.history.replaceState(null, '', url)
				}
			}
			return data
		}
	}).data
}

function useSetFatUser() {
	const qc = useQueryClient()
	return (updater: (old: FatUser) => FatUser) => {
		qc.setQueryData<FatUser>(['fat-user'], (old) => {
			if (old === undefined) {
				throw new Error('Impossible')
			}
			return updater(old)
		})
	}
}

export function useSignedIn(): boolean | undefined {
	const fatUser = useFatUser()
	if (fatUser === undefined) return
	return fatUser ? true : false
}

export function useSignOut() {
	const qc = useQueryClient()

	return useMutation({
		mutationFn: async () => {
			await api.delete('auth/session/')
			localStorage.removeItem('access_token')
			localStorage.removeItem('nonces')
			qc.setQueryData<FatUser | null>(['fat-user'], null)
		}
	})
}

export function useSetUserProfile() {
	const cacheManager = useGAHTEMRsCacheManager()

	const mutation = useMutation({
		mutationFn: async (profile: UserProfileContent) => {
			try {
				const raw = await api.put('user-profile/', {
					json: serialize<UserProfileContent>(profile),
				}).json()
				const result = deserialize<EMRInfo>(raw)

				cacheManager.add(result)
			} finally {
				cacheManager.invalidate()
			}
		}
	})
	return mutation
}

export function useTESTSetWelcomed() {
	const config = useConfig()
	const cacheManager = useGAHTEMRsCacheManager()

	const mutation = useMutation({
		mutationFn: async () => {
			try {
				const result = deserialize<EMRInfo>(await api.put('TEST/welcomed/').json())

				cacheManager.add(result)
			} finally {
				cacheManager.invalidate()
			}
		}
	})
	return config.testMode ? mutation : undefined
}

export async function declareIntent(request: IntentRequest): Promise<Intent> {
	const raw = await api.post('intents/', {
		json: serialize<IntentRequest>(request),
	}).json()
	return deserialize<Intent>(raw)
}

export function useIntent(request: IntentRequest): Intent | undefined {
	// Somewhat deprecated. Try to use declareIntent instead.
	const mut = useMutation({
		mutationFn: async () => {
			console.debug('useIntent', request)
			return await declareIntent(request)
		},
	})
	useEffect(() => {
		mut.mutate()
	}, [])
	return mut.data
}

export function useServices(): Map<ServiceID, ServiceInfo> | undefined {
	return new Map(TEMPORARILY_HERE_productsAndServices.filter(ps => isService(ps)).map(s => [s.ID, s]))
}

export function useProducts(): Map<ProductID, ProductInfo> | undefined {
	return new Map(TEMPORARILY_HERE_productsAndServices.filter(ps => isProduct(ps)).map(p => [p.ID, p]))
}

export function useServiceProducts(): Map<ServiceProductID, ServiceProductInfo> | undefined {
	return new Map(TEMPORARILY_HERE_productsAndServices.filter(ps => isServiceProduct(ps)).map(s => [s.ID, s]))
}

function getById<K, V>(map: Map<K, V> | undefined, k: K | undefined, error: () => Error): V | undefined {
	if (map === undefined || k === undefined) {
		return undefined
	}

	const v = map.get(k)
	if (v === undefined) {
		throw error()
	}

	return v
}

export function useService(ID: ServiceID | undefined): ServiceInfo | undefined {
	return getById(useServices(), ID, () => new Error('Invalid service ID.'))
}

export function useProduct(ID: ProductID | undefined): ProductInfo | undefined {
	return getById(useProducts(), ID, () => new Error('Invalid product ID.'))
}

export function useServiceProduct(ID: ServiceProductID | undefined): ServiceProductInfo | undefined {
	return getById(useServiceProducts(), ID, () => new Error('Invalid service product ID.'))
}

export function useAppointments(): AppointmentInfo[] | undefined {
	const config = useConfig()
	const fatUser = useFatUser()!
	if (config.testMode) { // TODO remove
		const welcomed = useWelcomed()
		if (welcomed === true)
			return fatUser.appointments?.filter(app => app.serviceID != WelcomeCall_ID)
	}
	return fatUser.appointments
}

export function useServiceAppointment(serviceID?: ServiceID): AppointmentInfo | null | undefined {
	const appointments = useAppointments()
	if (serviceID === undefined || appointments === undefined) return
	const filtered = appointments.filter(app => app.serviceID === serviceID)
	return filtered.length > 0 ? filtered[0] : null
}

export function useSubscription(): SubscriptionInfo | undefined {
	const fatUser = useFatUser()!
	return fatUser.subscription
}

export function useUnsubscribe_internal() {
	const cacheManager = useSubscriptionCacheManager()

	const mutation = useMutation({
		mutationFn: async () => {
			try {
				const data = await api.delete('subscription/').json()
				const subscription = deserialize<SubscriptionInfo>(data)

				// Work around bug in Deepkit.
				const periodEnds = (data as SerializedSubscriptionInfo).periodEnds
				if (periodEnds !== undefined) {
					subscription.periodEnds = periodEnds === null ? null : Instant.from(periodEnds)
				}

				cacheManager.set(subscription)
			} finally {
				cacheManager.invalidate()
			}
		}
	})

	return mutation
}


function useInventory(): ProductID[] | undefined {
	const fatUser = useFatUser()!
	return fatUser.inventory
}

export function useOwns(productID: ProductID) {
	const inventory = useInventory()
	return inventory?.includes(productID)
}

function lastEMROfKind(kind: EMRKindID) {
	const fatUser = useFatUser()
	if (!fatUser) return
	if (fatUser.emrs === undefined) return
	return fatUser.emrs.filter(emr => emr.kindID === kind)[0] ?? null
}

export function useProfile() {
	const lastProfile = lastEMROfKind(Profile_ID)
	if (lastProfile === undefined) return
	return lastProfile ? lastProfile.userProfile : null
}

export function useWelcomed(): boolean | undefined {
	const lastWelcomed = lastEMROfKind(Welcomed_ID)
	if (lastWelcomed === undefined) return
	return lastWelcomed ? true : false
}

export function useMigrated(): boolean | undefined {
	const lastMigrated = lastEMROfKind(Migrated_ID)
	if (lastMigrated === undefined) return
	return lastMigrated ? true : false
}

export function useLastGAHTBloodResults(): Temporal.Instant | null | undefined {
	const lastResults = lastEMROfKind(GAHTBloodResults_ID)
	if (lastResults === undefined) return
	return lastResults ? lastResults.timestamp : null
}

export function useGAHTIntaken(): boolean | undefined {
	const lastIntake = lastEMROfKind(GAHTIntake_ID)
	if (lastIntake === undefined) return
	return lastIntake ? true : false
}

export function useGAHTTherapy(): TherapyID | null | undefined {
	const draft = useGAHTDraftPrescription()
	const prescriptions = useGAHTPrescriptions()
	if (draft === undefined || prescriptions === undefined) return undefined
	return draft ? draft.therapy : prescriptions[0] !== undefined ? prescriptions[0].therapy : null
}

export function useGAHTFHTConsented(): boolean | undefined {
	const lastIntake = lastEMROfKind(GAHTFHTConsent_ID)
	if (lastIntake === undefined) return
	return lastIntake ? true : false
}

export function useGAHTMHTConsented(): boolean | undefined {
	const lastIntake = lastEMROfKind(GAHTMHTConsent_ID)
	if (lastIntake === undefined) return
	return lastIntake ? true : false
}

export function useGAHTConsented() {
	const therapy = useGAHTTherapy()
	const fht = useGAHTFHTConsented()
	const mht = useGAHTMHTConsented()
	if (therapy === undefined) return
	if (therapy === GAHTFHT_ID) return fht
	if (therapy === GAHTMHT_ID) return mht

	// TODO remove
	if (therapy === null) {
		if (fht === undefined || mht === undefined) return
		return fht || mht
	}

	return false
}

export interface PrescriptionInfo extends PrescriptionContent {
	basedOn?: Instant

	requestedOn?: Instant
	requestersComment?: string
	requestersAddress?: string

	issuedOn?: Instant
	receivedOn?: Instant
	runsOutOn?: PlainDate
}

export type RequestedPrescriptionInfo = PrescriptionInfo & {
	requestedOn: Instant
	requestersComment: string
	requestersAddress: string
}

export type IssuedPrescriptionInfo = PrescriptionInfo & {
	issuedOn: Instant
}

export type ReceivedPrescriptionInfo = IssuedPrescriptionInfo & {
	receivedOn: Instant
	runsOutOn: Instant
}

export function useGAHTPrescriptions(): (RequestedPrescriptionInfo | IssuedPrescriptionInfo)[] | undefined {
	const fatUser = useFatUser()!
	if (fatUser.emrs === undefined) return
	const prescriptionRequests = fatUser.emrs.filter(emr => emr.prescriptionRequest)
	const prescriptionDocuments = fatUser.emrs.filter(emr => emr.prescriptionDocument)
	const ret = [
		...prescriptionRequests.map((emr): RequestedPrescriptionInfo => {
			const pr = emr.prescriptionRequest!
			return {
				therapy: pr.draft.therapy,
				advice: pr.draft.advice,
				items: pr.draft.items,
				requestedOn: emr.timestamp,
				requestersComment: pr.comment,
				requestersAddress: pr.mailingAddress,
			}
		}),
		...prescriptionDocuments.map((emr): IssuedPrescriptionInfo => {
			const pd = emr.prescriptionDocument!
			return {
				therapy: pd.prescription.therapy,
				advice: pd.prescription.advice,
				items: pd.prescription.items,
				requestersAddress: pd.mailingAddress,
				issuedOn: emr.timestamp,
			}
		}),
	]
	return ret
}

export function useGAHTDraftPrescription(): PrescriptionInfo | null | undefined {
	const fatUser = useFatUser()!
	if (fatUser.emrs === undefined) return

	const lastPrescriptionLike = fatUser.emrs.filter(emr => emr.hasPrescriptionDraft || emr.prescriptionRequest || emr.prescriptionDocument)[0]
	if (!lastPrescriptionLike) return null

	if (lastPrescriptionLike.report?.prescriptionDraft)
		return lastPrescriptionLike.report?.prescriptionDraft

	if (lastPrescriptionLike.prescriptionRequest)
		return null

	if (lastPrescriptionLike.prescriptionDocument)
		return {
			basedOn: lastPrescriptionLike.timestamp,
			therapy: lastPrescriptionLike.prescriptionDocument.prescription.therapy,
			advice: lastPrescriptionLike.prescriptionDocument.prescription.advice,
			items: lastPrescriptionLike.prescriptionDocument.prescription.items,
			requestersAddress: lastPrescriptionLike.prescriptionDocument.mailingAddress,
		}
}

export function useRequestGAHTPrescription() {
	const cacheManager = useGAHTEMRsCacheManager()

	const mutation = useMutation({
		mutationFn: async ({draft, comment, mailingAddress}: {
			draft: PrescriptionContent | null,
			comment: string
			mailingAddress: string
		}) => {
			try {
				const result = deserialize<EMRInfo>(await api.post('gaht/prescription-requests/', {
					json: serialize<PrescriptionRequestContent>({draft: draft ?? {
						therapy: undefined,
						advice: '',
						items: [],
					}, comment, mailingAddress}),
				}).json())

				cacheManager.add(result)
			} finally {
				cacheManager.invalidate()
			}
		}
	})
	return mutation
}

export function useSubmitGAHTBloodResults() {
	const cacheManager = useGAHTEMRsCacheManager()

	const mutation = useMutation({
		mutationFn: async ({files}: {
			files: File[]
		}) => {
			try {
				const data = new FormData()
				let i = 0
				for (const file of files) {
					data.append(`file${i}`, file)
					i += 1
				}
				const result = deserialize<EMRInfo>(await api.post('gaht/blood-results/', {
					body: data,
				}).json())

				cacheManager.add(result)
			} finally {
				cacheManager.invalidate()
			}
		}
	})
	return mutation
}

export function useFakeSubmitGAHTBloodResults() {
	const config = useConfig()
	const cacheManager = useGAHTEMRsCacheManager()
	const mutation = {
		mutate: () => cacheManager.fake(GAHTBloodResults_ID),
		isPending: false,
	}
	return config.testMode ? mutation : null
}

export interface GAHTEMRsCacheManager {
	add(result: EMRInfo): void
	fake(kindID: EMRKindID): void
	invalidate(): void
}

export function useGAHTEMRsCacheManager(): GAHTEMRsCacheManager {
	const setFatUser = useSetFatUser()

	return {
		add(result) {
			setFatUser(userInfo => { return {
				...userInfo,
				emrs: [
					result,
					...userInfo.emrs || [],
				]
			} satisfies FatUser})
		},
		fake(kindID) {
			this.add({
				ID: 'dummy' as EMRID,
				timestamp: Temporal.Now.instant(),
				kindID,
				mimeType: 'whatever' as MimeType,
				callStatus: CALL_STATUSES.get(kindID),
				assistantCheckStatus: ASSISTANT_CHECK_STATUSES.get(kindID),
				hasWelcomed: kindID === WelcomeCall_WithWelcomed_ID,
				hasPreCallSummary: kindID === GAHTAssistantCheck_WithPreCallSummary_ID,
				hasPrescriptionDraft: kindID === GAHTSetupCall_WithPrescriptionDraft_ID,
			})
		},
		invalidate() {
			// TODO invalidate fatUser.emrs
		},
	}
}

export interface AppointmentsCacheManager {
	add(result: AppointmentInfo): void
	remove(cached: AppointmentInfo): void
	invalidate(): void
}

export function useAppointmentsCacheManager(): AppointmentsCacheManager {
	const setFatUser = useSetFatUser()
	const qc = useQueryClient()

	return {
		add(result) {
			setFatUser(fatUser => { return {
				...fatUser,
				appointments: [
					...fatUser.appointments || [],
					result,
				]
			} satisfies FatUser})
		},
		remove(cached) {
			setFatUser(fatUser => {
				return {
					...fatUser,
					appointments: fatUser.appointments!.filter(app => app.ycbm_secret != cached.ycbm_secret)
				}
			})
		},
		invalidate() {
			qc.invalidateQueries({queryKey: ['fat-user']})
		},
	}
}

function useSubscriptionCacheManager() {
	const setFatUser = useSetFatUser()

	return {
		set(subscription: SubscriptionInfo) {
			setFatUser(fatUser => { return {
				...fatUser,
				subscription,
			} satisfies FatUser})
		},
		invalidate() {
			// TODO invalidate fatUser.subscription
		},
	}
}
