import { useContext } from "react"
import { PageContext, PageSetContext } from "./App.tsx"
import { useProfile } from "../api.ts"
import { useInstallApp } from "../installApp.ts"

export function Hello({children}: {children?: any}) {
	const { shortName } = useProfile()!
	const page = useContext(PageContext)
	const setPage = useContext(PageSetContext)
	const installApp = useInstallApp()

	return <>
		<hgroup className="hello">
			<p className="display-large">Hello {shortName}!</p>
			<div role="toolbar">
				{page !== 'account' ? <button onClick={ev => {
					ev.stopPropagation()
					ev.preventDefault()
					setPage('account')
				}}>
					<span className="material-symbols-outlined">manage_accounts</span>
				</button> : <></>}
				{page === 'account' && installApp ? <>
					<button disabled={installApp.isPending} onClick={ev => {
						ev.preventDefault()
						ev.stopPropagation()
						installApp.mutate()
					}} className="only-on-expanded-plus">
						<img src={new URL('../icons/any.png?as=png&height=32', import.meta.url).toString()} height={16}/>Install Imago app
					</button>
				</> : <></>}
			</div>
		</hgroup>
		{page === 'account' && installApp ? <>
			<button type="submit" disabled={installApp.isPending} onClick={ev => {
				ev.preventDefault()
				ev.stopPropagation()
				installApp.mutate()
			}} className="only-on-medium-minus">
				<img src={new URL('../icons/any.png?as=png&height=32', import.meta.url).toString()} height={16}/>Install Imago app
			</button>
		</> : <></>}
		{children}
		<hr data-total/>
	</>
}
