import { calendar_v3 } from "googleapis"
import { encodePathParameter, GAPI_SkipProps, GoogleAPI, GoogleWorkspaceRemote } from "./_base.ts"
import type { GCalendar_CalendarID, GCalendar_EventID } from "./index.ts"

const CalendarV3API = new GoogleAPI('https://www.googleapis.com/calendar/v3/')

type CalendarV3_Events_SkipProps = 'calendarId' | 'eventId' | GAPI_SkipProps

export interface CalendarV3_Event extends calendar_v3.Schema$Event {
	id: GCalendar_EventID
}

export interface CalendarV3_Event_Patch extends Omit<CalendarV3_Event, 'id' | 'extendedProperties'> {
	extendedProperties?: {
		private?: {
			[key: string]: string | null
		} | null
		shared?: {
			[key: string]: string | null
		} | null
	} | null
}

export interface CalendarV3_EventList extends calendar_v3.Schema$Events {
	items: CalendarV3_Event[]
}

export async function CalendarV3_Events_list(
	remote: GoogleWorkspaceRemote,
	calendarID: string,
	options: Omit<calendar_v3.Params$Resource$Events$List, 'eventTypes' | 'privateExtendedProperty' | 'sharedExtendedProperty' | CalendarV3_Events_SkipProps> = {} // TODO add those options
) {
	return await CalendarV3API.call<CalendarV3_EventList>(remote, 'GET', `calendars/${encodePathParameter(calendarID)}/events`, options)
}

export async function CalendarV3_Events_get(
	remote: GoogleWorkspaceRemote,
	calendarID: string,
	ID: string,
	options: Omit<calendar_v3.Params$Resource$Events$Get, CalendarV3_Events_SkipProps> = {}
) {
	return await CalendarV3API.call<CalendarV3_Event>(remote, 'GET', `calendars/${encodePathParameter(calendarID)}/events/${encodePathParameter(ID)}`, options)
}

export async function CalendarV3_Events_patch(
	remote: GoogleWorkspaceRemote,
	calendarID: string,
	ID: string,
	data: CalendarV3_Event_Patch,
	options: Omit<calendar_v3.Params$Resource$Events$Patch, CalendarV3_Events_SkipProps> = {}) {
	return await CalendarV3API.call<CalendarV3_Event>(remote, 'PATCH', `calendars/${encodePathParameter(calendarID)}/events/${encodePathParameter(ID)}`, options, data)
}

type CalendarV3_CalendarList_SkipProps = GAPI_SkipProps

export interface CalendarV3_CalendarList extends calendar_v3.Schema$CalendarListEntry {
	id: GCalendar_CalendarID
}

export interface CalendarV3_CalendarListList extends calendar_v3.Schema$CalendarList {
	items: CalendarV3_CalendarList[]
}

export async function CalendarV3_CalendarList_list(
	remote: GoogleWorkspaceRemote,
	options: Omit<calendar_v3.Params$Resource$Calendarlist$List, CalendarV3_CalendarList_SkipProps> = {}
) {
	return await CalendarV3API.call<CalendarV3_CalendarListList>(remote, 'GET', `users/me/calendarList`, options)
}
